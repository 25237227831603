<template>
  <div class="user-fluid-layout__wrapper tour-style">
    <div v-if="isLoading || isError">
      <img
        src="@/assets/images/layout/user-fluid-layout/shimmer-bg.png"
        alt="layout-bg"
        :height="windowHeight"
      />
      <div class="user-fluid-layout__error" v-if="isError">
        <img
          src="@/assets/images/layout/user-fluid-layout/security.svg"
          alt=""
        />
        <p>{{ $t("layout.error") }}</p>
        <Button
          pill
          variant="primary-lighter"
          className="text-uppercase"
          @click="callRegisterApi"
          >{{ $t("general.try_again") }}</Button
        >
      </div>
    </div>
    <div v-else>
      <div class="user-fluid-layout__container">
        <div
          id="user-fluid-layout__top"
          :class="{
            'fixed-header': $route.name === 'TermsAndConditions'
          }"
        >
          <SystemAlert v-if="isSystemAlertEnabled" />
          <Header
            :expandSideNav="getExpandSideNav"
            :profileImageURL="profileImageURL"
            @logoClicked="navigateTo('/dashboard')"
            @toggleSideNav="toggleSideNav"
            @searchBarFocused="navigateTo('/course-discovery')"
            @onLogout="onLogout"
            :helpSectionHighlighted="helpSectionHighlighted"
            @isStartVueTour="isStartVueTour"
          />
        </div>
        <div
          :class="[
            'user-fluid-layout__side-nav-wrapper',
            { expand: getExpandSideNav }
          ]"
          :style="{ height: `calc(100% - ${offsetHeight}px)` }"
        >
          <SideNav
            :expandSideNav="getExpandSideNav"
            :termsAndConditionsUrl="
              `${allConfig.termsAndConditionsUrl}?language=${language}`
            "
            :menu="menu"
            @navigateTo="navigateTo"
            @onFeedback="onFeedback"
            @updateOnHover="updateOnHover"
            ref="sidenav"
          />
        </div>
        <TermsAndConditions v-if="$route.name === 'TermsAndConditions'" />
        <div
          id="userFluidLayoutMainViewWrapper"
          :class="[
            'user-fluid-layout__main-view-wrapper',
            { collapse: getExpandSideNav },
            { 'd-none': $route.name === 'TermsAndConditions' }
          ]"
          :style="{ height: `calc(100% - ${offsetHeight}px)` }"
        >
          <user-alerts
            :fluid-layout="true"
            v-if="showAlert && getAuthProfile.is_mfa_enabled === false"
          />
          <div class="user-fluid__main-container">
            <slot></slot>
            <!-- ref="userFluidDashbord" -->
          </div>
          <div class="footer-copyright__text">
            {{
              $t("landing_page.footer.copyright", {
                s: getYear()
              })
            }}
            <span class="mx-2 border-seprator">|</span>
            <a href="/terms-and-conditions" target="_blank">
              {{ $t("my_profile.terms_conditions") }}
            </a>
          </div>
        </div>
        <div class="user-fluid-layout__responsive-menu">
          <b-sidebar v-model="responsiveSidenavOpen" no-header>
            <template #footer="{}">
              <div class="text-left">
                <div class="menu-list" @click="startTour">
                  <img src="@/assets/images/icons/walkthrough.svg" />{{
                    $t("vle.vle_menu.walkthrough")
                  }}
                </div>
                <div class="menu-list">
                  <img src="@/assets/images/icons/download-app.svg" />{{
                    $t("general.download_app")
                  }}
                </div>
                <div class="menu-list">
                  <img src="@/assets/images/menu/feedback.svg" />{{
                    $t("my_profile.feedback")
                  }}
                </div>
                <div class="menu-list" @click="onLogout">
                  <img src="@/assets/images/icons/logout.svg" />{{
                    $t("my_profile.log_out.title")
                  }}
                </div>
              </div>
            </template>
          </b-sidebar>
        </div>
        <div
          class="user-fluid-layout__download-app-modal"
          v-if="downloadAppModalIsOpen"
        >
          <div>
            <div class="title text-left">
              {{ $t("layout.download_app.title") }}
            </div>
            <div class="content text-left">
              {{ $t("layout.download_app.subtitle") }}
            </div>
            <div class="d-flex justify-content-between">
              <div class="pr-1">
                <Button
                  variant="outline-primary"
                  pill="pill"
                  className="justify-content-center"
                  @click="downloadAppModalIsOpen = false"
                  >{{ $t("general.close") }}
                </Button>
              </div>
              <div class="pl-1">
                <Button
                  variant="primary"
                  pill="pill"
                  @click="downloadAppModalIsOpen = false"
                  >{{ $t("general.download") }}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <!-- mobile view footer -->
        <div
          v-if="showResponsiveFooter"
          id="userFluidLayoutFooter"
          class="user-fluid-layout__footer"
        >
          <div
            class="footer-box"
            v-for="(a, i) in menu"
            :key="i"
            :class="{ active: a.active }"
            @click="footerNavigateTo(a)"
          >
            <div>
              <img :src="a.active ? a.activeSrc : a.src" :alt="a.name" />
            </div>
            <div class="menu-name">
              {{ a.name }}
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <b-modal id="logout-modal" centered size="md">
        <template #modal-header="{ close }">
          <div class="d-flex justify-content-end w-100">
            <div class="close-icon-styles" @click="close()">
              <img src="@/assets/images/close.svg" alt="close" />
            </div>
          </div>
        </template>
        <div class="sp__body p-0">
          <div class="text-center">
            <img src="@/assets/images/vle/exit-modal.svg" alt="exit" />
          </div>

          <div class="sp__body--content text-center">
            <div class="sp__enroll--alert-title">
              {{ $t("my_profile.log_out.alert.title") }}
            </div>
            <div class="sp-sec__des text-center">
              <div class="sp__enroll--alert-message">
                {{ $t("my_profile.log_out.alert.subtitle") }}
              </div>
            </div>
          </div>
        </div>
        <template #modal-footer="{ hide }">
          <div class="w-100 d-flex justify-content-center">
            <Button
              variant="outline-primary"
              class="um__unfinish--button-close"
              @click="hide()"
              >{{ $t("general.cancel") }}</Button
            >
            <Button
              variant="custom-primary-vle"
              @click="logout"
              class="um__unfinish--button"
              >{{ $t("my_profile.log_out.alert.button") }}
            </Button>
          </div>
        </template>
      </b-modal>

      <!-- New user walkthrough model -->
      <b-modal
        id="start-tour-modal"
        v-model="welcomeModalStatus"
        centered
        header-class="tour-header-bg-background"
        hide-footer
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <template #modal-header="{ close }">
          <div class="close-icon">
            <img
              src="@/assets/images/close-white.svg"
              alt="close"
              @click="close()"
              role="button"
              width="24"
              height="24"
              aria-label="Close"
              tabindex="0"
              @keydown.enter="close()"
            />
          </div>
        </template>
        <template #default="{ close }">
          <div class="text-center pt-2">
            <div class="welcome-heading" id="modal-title">
              {{ $t("dashboard.tour.tour_title") }}
            </div>
            <div class="welcome-content">
              {{ $t("dashboard.tour.tour_message") }}
              <div class="disclaimer-box">
                <div class="disclaimer-heading">
                  {{
                    $t(
                      `environment.${allConfig.environment}.disclaimer.heading`
                    )
                  }}
                </div>
                <div class="disclaimer-content">
                  {{
                    $t(
                      `environment.${allConfig.environment}.disclaimer.content`
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center py-2">
              <LxpButton
                variant="primary"
                class="text-nowrap"
                @click="close(), startTour()"
                aria-label="Close and Start tour"
                role="button"
              >
                {{ $t("dashboard.tour.start_tour_now") }}
              </LxpButton>
            </div>
          </div>
        </template>
      </b-modal>
      <VueTour
        :steps="steps"
        :myCallbacks="myCallbacks"
        @bulletClick="myCustomNextStepCallback"
      ></VueTour>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
// mixins
import openFeedbackForm from "@/core/mixins/openFeedbackForm";
import authenticationMixin from "@/core/mixins/authentication";
import vTourMixin from "./mixins/vTour";
import userStatus from "@/core/mixins/userStatus";
import getCurrentYear from "@/core/mixins/getCurrentYear";

// child components
import Header from "./UserFluidLayout/Header.vue";
import SideNav from "./UserFluidLayout/SideNav.vue";
import UserAlerts from "../UserAlerts.vue";
import VueTour from "@/components/WrapperComponents/VueTour.vue";
import SystemAlert from "@/views/SystemAlert.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";

export default {
  name: "UserFluidLayout",
  mixins: [
    openFeedbackForm,
    authenticationMixin,
    vTourMixin,
    userStatus,
    getCurrentYear
  ],
  components: {
    Header,
    SideNav,
    UserAlerts,
    VueTour,
    LxpButton,
    SystemAlert,
    TermsAndConditions
  },
  data() {
    return {
      isLoading: false,
      windowHeight: 0,
      isError: false,
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      helpSectionHighlighted: false,
      myCallbacks: {
        onStart: this.onStart,
        onNextStep: this.myCustomNextStepCallback,
        onSkip: this.onSkip,
        onStop: this.onStop
      },
      responsiveSidenavOpen: false,
      downloadAppModalIsOpen: false,
      showFooterMenu: false,
      screenWidth: 480,
      menuArr: [
        {
          src: require("@/assets/images/menu/dashboard.svg"),
          activeSrc: require("@/assets/images/menu/dashboard-active.svg"),
          hoverSrc: require("@/assets/images/menu/dashboard-hover.svg"),
          name: this.$t("dashboard.title"),
          routeName: "dashboard",
          active: true,
          mouseOver: false,
          hasSubmenu: false,
          path: "/dashboard"
        },
        {
          src: require("@/assets/images/menu/discovery.svg"),
          activeSrc: require("@/assets/images/menu/discovery-active.svg"),
          hoverSrc: require("@/assets/images/menu/discovery-hover.svg"),
          name: this.$t("general.menu.discovery"),
          routeName: "course-discovery",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/course-discovery"
        },
        {
          src: require("@/assets/images/menu/my-learning.svg"),
          activeSrc: require("@/assets/images/menu/my-learning-active.svg"),
          hoverSrc: require("@/assets/images/menu/my-learning-hover.svg"),
          name: this.$t("general.menu.my_learning"),
          routeName: "my-learning",
          active: false,
          mouseOver: false,
          hasSubmenu: true,
          path: "",
          submenuArr: [
            {
              src: require("@/assets/images/menu/my-learning.svg"),
              activeSrc: require("@/assets/images/menu/my-learning-active.svg"),
              hoverSrc: require("@/assets/images/menu/my-learning-hover.svg"),
              name: this.$t("general.menu.my_programmes"),
              routeName: "my-programmes",
              active: false,
              mouseOver: false,
              hasSubmenu: false,
              path: "/my-programmes"
            },
            {
              src: require("@/assets/images/menu/my-learning.svg"),
              activeSrc: require("@/assets/images/menu/my-learning-active.svg"),
              hoverSrc: require("@/assets/images/menu/my-learning-hover.svg"),
              name: this.$t("general.menu.my_achievements"),
              routeName: "my-achievements",
              active: false,
              mouseOver: false,
              hasSubmenu: false,
              path: "/my-achievements"
            }
          ]
        },
        {
          src: require("@/assets/images/menu/settings.svg"),
          activeSrc: require("@/assets/images/menu/settings-active.svg"),
          hoverSrc: require("@/assets/images/menu/settings-hover.svg"),
          name: this.$t("general.menu.settings"),
          routeName: "settings",
          active: false,
          mouseOver: false,
          hasSubmenu: false,

          path: "/settings"
        },
        {
          src: require("@/assets/images/menu/feedback.svg"),
          activeSrc: require("@/assets/images/menu/feedback-active.svg"),
          hoverSrc: require("@/assets/images/menu/feedback-hover.svg"),
          name: this.$t("my_profile.feedback"),
          routeName: "feedback",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/feedback"
        }
      ],
      footerMenuArr: [
        {
          src: require("@/assets/images/menu/discovery.svg"),
          activeSrc: require("@/assets/images/menu/discovery-hover.svg"),
          name: this.$t("general.menu.discovery"),
          routeName: "course-discovery",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/course-discovery"
        },
        {
          src: require("@/assets/images/menu/my-learning.svg"),
          activeSrc: require("@/assets/images/menu/my-learning-hover.svg"),
          name: this.$t("general.menu.my_learning"),
          routeName: "my-programmes",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/my-programmes"
        },
        {
          src: require("@/assets/images/menu/dashboard.svg"),
          activeSrc: require("@/assets/images/menu/dashboard-hover.svg"),
          name: this.$t("dashboard.title"),
          routeName: "dashboard",
          active: true,
          mouseOver: false,
          hasSubmenu: false,
          path: "/dashboard"
        },
        {
          src: require("@/assets/images/menu/settings.svg"),
          activeSrc: require("@/assets/images/menu/settings-hover.svg"),
          name: this.$t("general.menu.settings"),
          routeName: "settings",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/settings"
        },
        {
          src: require("@/assets/images/menu/more.svg"),
          activeSrc: require("@/assets/images/menu/more-active.svg"),
          name: this.$t("general.menu.more"),
          routeName: null,
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: null
        }
      ],
      showResponsiveFooter: true,
      offsetHeight: 80
    };
  },
  watch: {
    "$route.name"() {
      this.showFooter();
      this.setHeight();
    },
    language(nVal, oVal) {
      if (nVal !== oVal) {
        this.menuItems();
      }
    },
    isSystemAlertEnabled() {
      setTimeout(() => {
        this.setHeight();
      }, 100);
    }
  },
  computed: {
    ...mapGetters([
      "language",
      "allConfig",
      "getExpandSideNav",
      "getAuthProfile",
      "getSelectedMenu",
      "vueTourStatus",
      "isSystemAlertEnabled",
      "welcomeModalStatus",
      "getOnboardingStatus",
      "chatbotLanguages"
    ]),
    welcomeModalStatus: {
      get() {
        return this.$store.getters.welcomeModalStatus;
      },
      set() {
        this.$store.commit("SET_WELCOME_MODAL_STATUS", false);
      }
    },
    steps() {
      if (window.innerWidth <= 480) {
        return [
          {
            target: "#userFluidLayoutFooter",
            content: this.$t("dashboard.tooltip_icons.sidebar"),
            params: {
              placement: "bottom"
            }
          },
          {
            target: "#dashboardRecommendations",
            content: this.$t("dashboard.tooltip_icons.recommendations"),
            params: {
              placement: "top"
            }
          },
          {
            target: "#mobileSearchBarIcon",
            content: this.$t("dashboard.tooltip_icons.search"),
            params: {
              placement: "top"
            }
          }
        ];
      } else if (this.chatbotLanguages.includes(this.language)) {
        return [
          {
            target: "#userFluidSidebar",
            content: this.$t("dashboard.tooltip_icons.sidebar"),
            params: {
              placement: "right"
            }
          },

          {
            target: "#dashboardRecommendations",
            content: this.$t("dashboard.tooltip_icons.recommendations"),
            params: {
              placement: "bottom"
            }
          },
          {
            target: "#dashboardDiscover",
            content: this.$t("dashboard.tooltip_icons.discover"),
            params: {
              placement: "top"
            }
          },
          {
            target: "#userFluidLayoutHeaderSearchBar",
            content: this.$t("dashboard.tooltip_icons.search"),
            params: {
              placement: "top"
            }
          },
          {
            target: ".rw-widget-container",
            content: this.$t("dashboard.tooltip_icons.chatbot"),
            params: {
              placement: "top"
            }
          },
          {
            target: "#userFluidLayoutHelpIcon",
            content: this.$t("dashboard.tooltip_icons.help")
          }
        ];
      } else {
        return [
          {
            target: "#userFluidSidebar",
            content: this.$t("dashboard.tooltip_icons.sidebar"),
            params: {
              placement: "right"
            }
          },
          {
            target: "#dashboardRecommendations",
            content: this.$t("dashboard.tooltip_icons.recommendations"),
            params: {
              placement: "bottom"
            }
          },
          {
            target: "#dashboardDiscover",
            content: this.$t("dashboard.tooltip_icons.discover"),
            params: {
              placement: "top"
            }
          },
          {
            target: "#userFluidLayoutHeaderSearchBar",
            content: this.$t("dashboard.tooltip_icons.search"),
            params: {
              placement: "top"
            }
          },
          {
            target: "#userFluidLayoutHelpIcon",
            content: this.$t("dashboard.tooltip_icons.help")
          }
        ];
      }
    },
    menu() {
      return this.menuItems();
    },
    profileImageURL() {
      return this.getAuthProfile.profile_image_url
        ? this.getAuthProfile.profile_image_url
        : require("@/assets/images/layout/user-fluid-layout/profile.svg");
    },
    showAlert() {
      let bool = true;
      if (this.$route.name === "Dashboard" || this.$route.name === "settings") {
        bool = false;
      }
      return bool;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.$nextTick(() => {
      this.$store.dispatch("getUserAuthProfile");
    });
    this.checkUserStatus();
    this.windowHeight = window.innerHeight;
    this.events.forEach(event => {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.clearTimers();
  },
  destroyed() {
    this.events.forEach(event => {
      window.removeEventListener(event, this.resetTimer);
    }, this);
  },
  methods: {
    showFooter() {
      this.showResponsiveFooter = !(
        window.innerWidth < 480 &&
        this.$route.name.toUpperCase() === "COURSES DETAIL"
      );
    },
    menuItems() {
      let menuArr = [
        {
          src: require("@/assets/images/menu/dashboard.svg"),
          activeSrc: require("@/assets/images/menu/dashboard-active.svg"),
          hoverSrc: require("@/assets/images/menu/dashboard-hover.svg"),
          name: this.$t("dashboard.title"),
          routeName: "dashboard",
          active: true,
          mouseOver: false,
          hasSubmenu: false,
          path: "/dashboard"
        },
        {
          src: require("@/assets/images/menu/discovery.svg"),
          activeSrc: require("@/assets/images/menu/discovery-active.svg"),
          hoverSrc: require("@/assets/images/menu/discovery-hover.svg"),
          name: this.$t("general.menu.discovery"),
          routeName: "course-discovery",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/course-discovery"
        },
        {
          src: require("@/assets/images/menu/my-learning.svg"),
          activeSrc: require("@/assets/images/menu/my-learning-active.svg"),
          hoverSrc: require("@/assets/images/menu/my-learning-hover.svg"),
          name: this.$t("general.menu.my_learning"),
          routeName: "my-learning",
          active: false,
          mouseOver: false,
          hasSubmenu: true,
          path: "",
          submenuArr: [
            {
              src: require("@/assets/images/menu/my-learning.svg"),
              activeSrc: require("@/assets/images/menu/my-learning-active.svg"),
              hoverSrc: require("@/assets/images/menu/my-learning-hover.svg"),
              name: this.$t("general.menu.my_programmes"),
              routeName: "my-programmes",
              active: false,
              mouseOver: false,
              hasSubmenu: false,
              path: "/my-programmes"
            },
            {
              src: require("@/assets/images/menu/my-learning.svg"),
              activeSrc: require("@/assets/images/menu/my-learning-active.svg"),
              hoverSrc: require("@/assets/images/menu/my-learning-hover.svg"),
              name: this.$t("general.menu.my_achievements"),
              routeName: "my-achievements",
              active: false,
              mouseOver: false,
              hasSubmenu: false,
              path: "/my-achievements"
            }
          ]
        },
        {
          src: require("@/assets/images/menu/settings.svg"),
          activeSrc: require("@/assets/images/menu/settings-active.svg"),
          hoverSrc: require("@/assets/images/menu/settings-hover.svg"),
          name: this.$t("general.menu.settings"),
          routeName: "settings",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/settings"
        },
        {
          src: require("@/assets/images/menu/feedback.svg"),
          activeSrc: require("@/assets/images/menu/feedback-active.svg"),
          hoverSrc: require("@/assets/images/menu/feedback-hover.svg"),
          name: this.$t("my_profile.feedback"),
          routeName: "feedback",
          active: false,
          mouseOver: false,
          hasSubmenu: false,
          path: "/feedback"
        }
      ];
      const menuArray = this.showFooterMenu ? this.footerMenuArr : menuArr;
      const currentRouteName = this.$route.name.toLowerCase();
      const isResponsiveSidenavOpen = this.responsiveSidenavOpen;
      const selectedMenu = this.getSelectedMenu?.toLowerCase() || "";

      menuArray.forEach(menuItem => {
        menuItem.active = false;
        if (menuItem.routeName) {
          const isCurrentRoute =
            currentRouteName === menuItem.routeName.toLowerCase() &&
            !isResponsiveSidenavOpen;
          const isCourseDetailRoute =
            currentRouteName === "courses detail" && !isResponsiveSidenavOpen;
          const isMatchingSelectedMenu =
            `/${menuItem.routeName.toLowerCase()}` === selectedMenu;
          menuItem.active =
            isCurrentRoute || (isCourseDetailRoute && isMatchingSelectedMenu);
        } else {
          menuItem.active = isResponsiveSidenavOpen;
        }
        menuItem.submenuArr?.forEach(subMenuItem => {
          subMenuItem.active = false;
          if (
            this.$route.name.toLowerCase() ===
            subMenuItem.routeName.toLowerCase()
          ) {
            subMenuItem.active = true;
          }
        });
      });
      return menuArray;
    },
    updateOnHover({ obj, value }) {
      const index = this.menu.findIndex(item => item.name === obj.name);
      if (index !== -1) {
        this.menu[index].mouseOver = value;
      }
    },
    navigateTo(path) {
      this.$store.commit("SET_SELECTED_MENU", path);
      if (path === "/feedback") {
        this.onFeedback();
      } else {
        this.$router.push({ path: path }).catch(() => {});
      }
    },
    footerNavigateTo(a) {
      if (a.path) {
        this.responsiveSidenavOpen = false;
        this.navigateTo(a.path);
      } else {
        this.responsiveSidenavOpen = !this.responsiveSidenavOpen;
      }
    },
    toggleSideNav() {
      this.$store.commit("TOGGLE_SIDE_NAV");
    },
    onFeedback() {
      this.openFeedbackForm();
    },
    onLogout() {
      this.$bvModal.show("logout-modal");
    },
    callRegisterApi() {
      this.isLoading = true;
      this.$store
        .dispatch("getUserRegistrationStatus")
        .then(() => {
          this.isLoading = false;
          this.isError = false;
          this.checkOnboardingStatus();
        })
        .catch(() => {
          this.isError = true;
        });
    },
    setTimers() {
      this.warningTimer = setTimeout(() => {
        this.helpSectionHighlighted = true;
      }, 30000);
    },
    resetTimer() {
      this.clearTimers();
      this.setTimers();
      this.helpSectionHighlighted = false;
    },
    clearTimers() {
      clearTimeout(this.warningTimer);
    },
    onResize() {
      if (window.innerWidth <= this.screenWidth) {
        this.showFooterMenu = true;
        this.downloadAppModalIsOpen = true;
      } else {
        this.showFooterMenu = false;
        this.downloadAppModalIsOpen = false;
      }
      this.showFooter();
      this.setHeight();
    },
    setHeight() {
      this.$nextTick(() => {
        this.offsetHeight = document.getElementById(
          "user-fluid-layout__top"
        )?.offsetHeight;
      });
    }
  }
};
</script>
<style lang="scss">
.user-fluid-layout__wrapper {
  min-height: 100%;
  background: $brand-primary-50;
  height: 100%;

  .question-circle-fill {
    color: #0043ac;
  }

  .user-fluid-layout__main-view-wrapper,
  .user-fluid-layout__side-nav-wrapper {
    position: fixed;
    overflow: hidden;
  }

  .user-fluid-layout__side-nav-wrapper {
    background: $brand-primary-50;
    height: calc(100% - #{$ufl-header-height});
    padding: 18px 0 0 20px;
    width: calc($ufl-sidenav-default-width + 20px);
    transition: width 0.5s;

    .submenu-dropdown {
      position: absolute;
      left: calc(100% - 6px);
      margin-top: 55px;
      display: none;
      z-index: 9999;
      .sub-menu {
        width: 154px;
        margin: 0px 0px 0px 10px !important;
        padding: 8px;
        background-color: $brand-neutral-0;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
        border-radius: 8px;
        li {
          margin: 0;
          padding: 8px;
          min-height: auto;
          width: 100%;
          font-weight: 400;
          &:not(:first-child) {
            margin-top: 8px;
          }
          &.active {
            font-weight: 400;
          }
        }
      }
    }

    &.expand {
      width: $ufl-sidenav-expand-width;
    }

    &:not(.expand) {
      overflow: visible;
      z-index: 1;
      .hover-item {
        &:hover {
          mix-blend-mode: unset;
          .submenu-dropdown {
            display: block;
          }
        }
      }
    }
  }

  .user-fluid-layout__main-view-wrapper {
    height: calc(100% - #{$ufl-header-height});
    overflow-y: auto;
    right: 0;
    width: calc(100% - (#{$ufl-sidenav-default-width} + 20px));
    transition: width 0.5s;
    background: $brand-primary-50;

    &.collapse {
      display: block;
      /* override utility class */
      width: calc(100% - #{$ufl-sidenav-expand-width});
    }
    .user-fluid__main-container {
      min-height: 95%;
    }
    .footer-copyright__text {
      text-align: right;
      margin: 20px 7%;
      margin-top: 0px;
      font-size: 12px;
      .border-seprator {
        color: $brand-neutral-400;
      }
    }
  }

  .user-fluid-layout__error {
    position: absolute;
    width: 600px;
    left: calc(50% - 600px / 2);
    top: 30%;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 5px 4px 20px rgb(80 80 80 / 15%);
    border-radius: 16px;
    padding: 36px;
    @include center;

    p {
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      margin: 15px 0 30px;
    }
  }

  .user-fluid-layout__footer {
    display: none;
  }

  .user-fluid-layout__responsive-menu {
    .b-sidebar {
      background: rgba(45, 45, 45, 0.25) !important;
      backdrop-filter: blur(4px);
      height: calc(100% - 74px);
      width: 100%;

      .b-sidebar-footer {
        box-shadow: 0px 2px 10px rgb(9 44 78 / 3%),
          0px 9px 30px rgb(9 44 78 / 5%);
        background: #f9fcff !important;
        color: #00205c !important;
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: left;
        border-radius: 4px 4px 0px 0px;

        .menu-list {
          height: 56px;
          padding: 16px 0px 16px 25px;

          img {
            padding-right: 8px;
          }
        }
      }
    }
  }

  .user-fluid-layout__download-app-modal {
    position: fixed;
    left: 0px;
    bottom: 74px;
    height: 182px;
    background: #ffffff;
    box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.25);
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    font-weight: 400;
    padding: 24px;

    .title {
      font-size: 24px;
      line-height: 29px;
      color: $brand-neutral-900;
      padding-bottom: 8px;
    }

    .content {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 16px;
    }

    .btn {
      padding: 0px 32px !important;
      height: 49px;
      gap: 8px;
    }

    .btn-outline-primary {
      background-color: transparent !important;

      &:active {
        border-color: $brand-primary-700 !important;
        color: $brand-primary-700 !important;
      }
    }

    .btn-primary {
      &:focus {
        background-color: #00205c;
        color: #f4f9ff;
      }
    }
  }

  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $brand-primary-50;
    z-index: 1;
    padding-bottom: 18px;
    + .user-fluid-layout__side-nav-wrapper {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .user-fluid-layout__side-nav-wrapper {
      display: none !important;
    }

    .user-fluid-layout__main-view-wrapper {
      width: 100% !important;
      padding-bottom: 75px;
      margin-top: 0px;
    }

    .user-fluid-layout__wrapper {
      display: block;
      position: relative;
    }

    .user-fluid-layout__footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 8px;

      position: fixed;
      height: 74px;
      left: 0px;
      right: 1px;
      bottom: 0px;
      background: #ffffff;
      /* Shadow ↑ 1.5 */
      box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.25);
    }

    .footer-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 0px;
      gap: 4px;
      font-size: 9px;
      pointer-events: none;
      width: 65.6px;
      height: 58px;
      border-radius: 8px;

      /* Inside auto layout */
      order: 0;
      flex-grow: 1;

      &:last-child {
        pointer-events: all;
      }
      &:nth-child(3) {
        pointer-events: all;
      }
      &:nth-child(2) {
        pointer-events: all;
      }
      &.active {
        background: #e6f2ff;
        border-radius: 8px;
        color: #0081ff;

        img {
          filter: invert(38%) sepia(80%) saturate(3832%) hue-rotate(195deg)
            brightness(101%) contrast(106%);
        }
      }
    }
  }
}

.tour-header-bg-background {
  background-image: url("../../assets/images/dashboard/tour-header-bg.svg");
  background-size: 100%;
  height: 150px;
  background-repeat: no-repeat;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 0;
  position: relative;
}

.close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.welcome-heading {
  @include subtitle-large;
}

.welcome-content {
  @include body-regular;
  margin-top: 8px;
}

.disclaimer-box {
  background-color: $brand-primary-100;
  border-radius: 8px;
  padding: 16px;
  margin: 24px 0px 16px;
  .disclaimer-heading {
    @include body-regular($brand-neutral-900, 500);
  }
  .disclaimer-content {
    @include label-medium;
    color: $brand-neutral-700;
    margin-top: 4px;
  }
}

.v-tour--active {
  .user-fluid-layout__main-view-wrapper {
    &.is__highlighted {
      background: transparent;
      z-index: 99;
    }
  }

  .user-fluid-layout__side-nav-wrapper {
    background: none;
    z-index: 99 !important;
  }
}
#logout-modal {
  .modal-dialog {
    max-width: 583px;
  }
  .modal-header {
    border: none;
    padding: 12px;
  }
  .modal-footer {
    justify-content: center;
    border: none;
    padding-bottom: 24px;
    .btn {
      padding: 8px 32px;
      border-radius: 100px;
      text-transform: capitalize;
      @include dashboard-label;
      &.um__enrolled-dismiss {
        background: $brand-neutral-0;
        border: 1px solid $brand-primary;
        color: $brand-primary;
        &:hover {
          background: $brand-neutral-0 !important;
          color: $brand-primary !important;
        }
      }
    }
  }
  .modal-body {
    padding: 0 10px 0;
    .cd-enroll__div {
      &.cd-main {
        padding: 0;
        .cd-main__sec {
          box-shadow: none;
          padding: 0;
        }
      }
      .cd-enroll__sec {
        padding: 5% 15%;
        h3 {
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.2px;
          color: #0043ac;
          margin-left: 5%;
          padding: 7% 12% 2%;
          font-weight: 500;
        }
      }
      .cd-enroll__message {
        @include body-regular;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        &.fw-500 {
          font-weight: 500;
        }
      }
    }
  }
  .um__unfinish--button {
    @include button-label;
    @include modal-button-size;
    width: 193px;
    gap: 8px;
    margin-right: 26px;
  }
  .um__unfinish--button-close {
    @include button-label;
    @include modal-button-size;
    width: 120px;
    gap: 8px;
    margin-right: 26px;
  }
  .sp__enroll--alert-title {
    @include subtitle-large;
    letter-spacing: 0.0025em;
    color: $brand-neutral-900;
    padding-top: 18px;
    padding-bottom: 8px;
  }
  .sp__enroll--alert-message {
    @include body-regular;
    margin-bottom: 16px;
    padding: 0 6px;
  }
  .close-icon--styles {
    right: 20px;
    top: 20px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    position: absolute;
    cursor: pointer;
    border-radius: 16px;
    z-index: 1;
  }
}
[dir="rtl"] {
  .user-fluid-layout__wrapper {
    .user-fluid-layout__side-nav-wrapper {
      margin: 0 20px 0 0;
      .submenu-dropdown {
        left: calc(100% - 223px);
      }
    }

    .user-fluid-layout__responsive-menu {
      .b-sidebar {
        .b-sidebar-footer {
          .menu-list {
            img {
              padding-left: 8px;
            }
          }
        }
      }
    }
  }
  .user-fluid-layout__wrapper {
    .user-fluid-layout__side-nav-wrapper {
      margin: 0 20px 0 0;
    }

    .user-fluid-layout__responsive-menu {
      .b-sidebar {
        .b-sidebar-footer {
          .menu-list {
            img {
              padding-left: 8px;
            }
          }
        }
      }
    }
  }
}
#userFluidLayoutMainViewWrapper.highlight_recommendations {
  top: 40px;
  #dashboardRecommendations {
    min-height: 350px;
  }
}
</style>
